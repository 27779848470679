import "./bootstrap";

import App from "./App.vue";
import {createApp, markRaw} from "vue";
import {createPinia} from "pinia";
import {createHead} from "@vueuse/head";
import {router} from "./routes/routes";
import {toastOptions} from "./config/toastOptions";
import Toast from "vue-toastification";
import setupSentry from "./helpers/SetupSentry";
import "../css/toast-message.css";

localStorage.theme = "light";

const pinia = createPinia();
const head = createHead();
const app = createApp(App);

const sentryDsn = import.meta.env.VITE_SENTRY_DSN_PUBLIC;
if (sentryDsn) setupSentry(sentryDsn, app, router);

pinia.use(({store}) => {
  store.router = markRaw(router);
});

app.use(router);
app.use(pinia);
app.use(head);
app.use(Toast, toastOptions);

app.mount("#app");
