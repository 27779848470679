<template>

  <RouterView v-slot="{ Component }">
    <Component :is="$route.meta.layout || 'div'">
      <Component :is="Component" :key="pageReloadKey"/>
    </Component>
  </RouterView>

  <Component v-if="tourInfo.show" :is="guidedTour" :type="tourInfo.type" :is-required="tourInfo.isRequired"/>
  <Component v-if="envComponent" :is="envComponent"/>

</template>

<script setup>
import {defineAsyncComponent, provide, reactive, ref} from "vue";
import {ENV_DEMO} from "./constants/EnvironmentType";

const tourInfo = reactive({
  show: false,
  type: null,
  isRequired: false,
});

const pageReloadKey = ref(0);

const startGuidedTour = (type, isRequired = false) => {
  tourInfo.show = true;
  tourInfo.type = type;
  tourInfo.isRequired = isRequired;
};

const endGuidedTour = () => {
  tourInfo.show = false;
  tourInfo.type = null;
  tourInfo.isRequired = false;
};

const pageReload = () => pageReloadKey.value++;

provide("startGuidedTour", startGuidedTour);
provide("endGuidedTour", endGuidedTour);
provide("pageReload", pageReload);

const guidedTour = defineAsyncComponent({
  loader: () => import("./components/GuidedTour.vue"),
});

const envHandler = () => {
  switch (import.meta.env.VITE_ENV) {
  case ENV_DEMO:
    return defineAsyncComponent({
      loader: () => import("./components/DemoEnvironmentSetup.vue"),
    });
  default:
    return null;
  }
};

const envComponent = envHandler();
</script>
